import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import StaticBG from "../assets/images/pages/parents-hero.jpg"

export default function Parents({ data }) {
  return (
    <Layout>
      <Seo
        title="Parents"
        description="Information for Parents"
      />

      <div id="parents" className="brochure">
        <section
          id="brochure-hero"
          style={{ backgroundImage: "url(" + StaticBG + ")" }}
        >
          <div id="brochure-hero-overlay">
            <div className="intro">
              <h2>Parents</h2>
              <div className="tagline">
                Everything you need to know to support your creative teen
              </div>
            </div>
          </div>
        </section>
        <section id="brochure-features">
          <div id="parents-meaning" className="feature-thumb">
            <div className="image">&nbsp;</div>
            <div className="text">
              <h3>The Meaning of the Awards</h3>
              <p>
                Recognition for your teen through opportunities for exhibition,
                publication, and more.
              </p>
              <Link to="/awards/recognition/" className="button">
                Learn More
              </Link>
            </div>
          </div>
          <div id="parents-scholarships" className="feature-cover">
            <div className="image">&nbsp;</div>
            <div className="text">
              <h3>
                <span>Scholarships</span>
              </h3>
              <p>
                Creative teens can receive direct scholarships or tuition
                assistance through the Awards.
              </p>
              <Link to="/scholarships" className="button">
                Discover the Scholarships
              </Link>
            </div>
          </div>
          <div className="feature-rule">
            <hr />
          </div>
          <div id="parents-how" className="feature-thumb-alt">
            <div className="image">&nbsp;</div>
            <div className="text">
              <h3>How to Enter</h3>
              <p>
                Find your region, help your student create an account and upload
                work, and sign the submission form before the deadline.
              </p>
              <Link to="/regions" className="button">
                Get Started
              </Link>
            </div>
          </div>
          <div id="parents-support" className="feature-cover-alt">
            <div className="image">&nbsp;</div>
            <div className="text">
              <h3>
                <span>Ways to Support Your Teen</span>
              </h3>
              <p>
                Review the Copyright &amp; Plagiarism Guidelines and
                Participation Terms with your teen.
              </p>
              <Link to="/awards/how-to-enter/copyright-plagiarism/" className="button">
                Learn More
              </Link>
            </div>
          </div>
          <div className="feature-rule">
            <hr />
          </div>
        </section>
        <section id="brochure-faq">
          <div className="faq-widget">
            <h3>FAQ</h3>
            <ul>
            {data.parentFaqs.nodes.map((faq, index) => (
                      <li key={index}>
                        <Link to={faq.uri}>
                        <strong>{faq.title}</strong>
                        <span>{parse(faq.excerpt)}</span>
                        </Link>
                      </li>
                      ))}
            </ul>
            <Link to="/frequently-asked-questions/" className="button">
              View More
            </Link>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  fragment Thumbnail on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
  query {
    site {
      siteMetadata {
        author
        description
        title
      }
    }
    
    parentFaqs: allWpFaq(filter: {faqTags: {nodes: {elemMatch: {slug: {eq: "parents"}}}}}) {
    nodes {
      id
      title
      content
      excerpt
      uri
    }
  }

  }
`
